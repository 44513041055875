import React from 'react';

const Up = () => {
    return (
        <>
            <button className="up" id="btn-up"><i className="fa-solid fa-arrow-up" /></button>
        </>
    );
}

export default Up;
